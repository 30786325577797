import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ShopsService } from 'src/app/services/shops.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StateService } from 'src/app/services/state.service';
import { ShopInviteService } from 'src/app/services/shop-invite.service';
import { UserService } from 'src/app/services/user.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { randomString } from 'src/app/libs/random-string';

@Component({
  selector: 'app-shop-users-settings',
  templateUrl: './shop-users-settings.component.html',
  styleUrls: ['./shop-users-settings.component.scss']
})
export class ShopUsersSettingsComponent {

  shop: any
  invites: any[]
  email = ''
  loading = false

  constructor(private route: ActivatedRoute, private snackBar: MatSnackBar, public state: StateService, private invitesService: ShopInviteService, private http: HttpClient) {
    this.shop = this.route.snapshot.data.data.shop
    this.invites = this.route.snapshot.data.data.invites
  }

  async submitForm(form: NgForm) {
    if (!form.valid || this.loading) return
    if (this.state.user.email === this.email || this.invites.find(invite => invite.email === this.email)) {
      return this.snackBar.open('This email address is already invited', 'close', { duration: 2000 })
    }
    this.loading = true
    let password = randomString(12)
    let signupResp = <any>await this.http.post(environment.apiEndpoint + '/signup', { email: this.email, password, passwordIsAutoGenerated: true, dontSendEmail: true }).toPromise()
    if (signupResp && signupResp.status) {
      let resp = await this.invitesService.inviteToShop(this.email, password)
      if (resp && resp.data) {
        this.invites = await this.invitesService.getShopInvites(this.shop._id)
        this.snackBar.open('Invite sent', 'close', { duration: 2000 })
      } else {
        this.snackBar.open(resp.error, 'close', { duration: 2000 })
      }
    } else {
      if (signupResp.error === 'ALREADY_EXISTS') signupResp.error = 'A sprink account with that email already exists'
      this.snackBar.open(signupResp.error, 'close', { duration: 2000 })
    }
    this.loading = false
  }

  async deleteInvite(id: string) {
    if (!confirm('Are you sure you want to remove this user?')) return
    await this.invitesService.deleteShopInvite(id)
    this.invites = await this.invitesService.getShopInvites(this.shop._id)
    this.snackBar.open('User removed', 'close', { duration: 2000 })
  }

}
