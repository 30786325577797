import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-referral-ad',
  templateUrl: './referral-ad.component.html',
  styleUrls: ['./referral-ad.component.scss']
})
export class ReferralAdComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
