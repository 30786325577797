<div class="page center">
  <br>
  <img src="/assets/wait.png" class="wait-img" alt="">
  <h1>Account created!</h1>

  <br>
  <h3>Sprink is not available in your area yet.</h3>
  <p>We will notify you when this changes. Sorry for any inconvenience.</p>

  <br><br>
  <app-referral-ad></app-referral-ad>
</div>