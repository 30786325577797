<form name="partnerForm" #partnerForm="ngForm" (submit)="submitForm(partnerForm)" *ngIf="!success">
  <!-- <div class="formrow break-on-mobile">
    <mat-form-field appearance="outline">
      <mat-label>Shop name</mat-label>
      <input matInput type="text" name="shopName" [(ngModel)]="shopName" id="shopName" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Phone number</mat-label>
      <input matInput type="tel" name="phoneNumber" [(ngModel)]="phoneNumber" id="phoneNumber" required>
    </mat-form-field>
  </div> -->

  <div class="formrow break-on-mobile">
    <mat-form-field appearance="outline" class="bottomless">
      <mat-label>Your email</mat-label>
      <input matInput type="email" name="email" minlength="5" maxlength="50" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}" [(ngModel)]="email" id="email" required>
    </mat-form-field>
      <button mat-flat-button color="accent" class="next-to-input" *ngIf="!loading">START</button>
    <!-- <mat-form-field appearance="outline">
      <mat-label>Personal phone number</mat-label>
      <input matInput type="tel" name="phoneNumber" maxlength="14" [(ngModel)]="phoneNumber" id="phoneNumber" required>
    </mat-form-field> -->
    <!-- <mat-form-field appearance="outline">
      <mat-label>Shop address</mat-label>
      <input matInput type="text" name="address" [(ngModel)]="address" id="address" required>
    </mat-form-field> -->
  </div>

  <p class="legal bottomless">By continuing you agree to our <a routerLink="/service-provider-terms" class="link">terms</a>.</p>

  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
</form>

<div *ngIf="success">
  <b>Thank you for signing up. Check your email for a verification link.</b>
</div>