<div class="section accent primary bubbles-container">
  <app-bubbles></app-bubbles>
  <div class="page center page-intro">
    <h1>Dry cleaner FAQs</h1>
  </div>
</div>

<div class="section">
  <div class="page wide-page sprink-text">

    <button mat-button class="back-button" (click)="goBack()">
      <mat-icon>arrow_back_ios</mat-icon> BACK
    </button>
    
    <mat-accordion>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How can Sprink boost my business?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Sprink makes it super easy for customers to order from you. We will also advertise to potential customers to get you more business.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How many customers can I expect to get from Sprink?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          We will do our best to get you as many customer as possible by advertising directly to potential customers.
        </p>
        <p>
          We can't give you an exact number, however it can't hurt to try! Sign up <a routerLink="/become-a-partner" class="link">here</a>.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How does Sprink make money?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Sprink takes a 9.5% cut from orders placed via the app.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How much does Sprink cost?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          There is a £220 admin fee for listing your Shop with Sprink.<br>
          Note: Admin fee is currently free. More details <a routerLink="/become-a-partner" class="link">here</a>.
        </p>
        <p>
          Sprink takes a 9.5% cut from orders placed via the app.<br>
          You can find more details and even use our payout calculator <a routerLink="/payouts-explained" class="link">here</a>.
        </p>
      </mat-expansion-panel>

      <!-- <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Does Sprink have discounts or promotions?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Yes, we will offer some customers discounts.
        </p>
      </mat-expansion-panel> -->

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Are there any hidden fees?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          No!
        </p>
        <p>
          We pride ourselves on transparency. You can get a full picture of exactly how much you will earn using our <a routerLink="/payouts-explained" class="link">pricing calculator</a>.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Can I cancel at any time?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Yes!
        </p>
        <p>
          You can close your shop at any time (at no cost). This will instantly de-list your dry cleaner from our app.<br>
          You can then open again when you are ready.
        </p>
        <p>
          Note: You will still need to process any orders that were placed before you closed your shop.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Who will pick up an deliver the customer's order?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          As a Sprink dry cleaner, you'll need to do the pickup and delivery of items. We recommend that you handle all the
          pickups and all the deliveries for each day in one trip - in what we call a 'Sprink run' - to save you time.
        </p>
        <p>
          You'll see pickups and deliveries displayed on your dashboard. Staff accounts that you've added to your shop will be
          able to see these and process each one on the app as they make their Sprink run.
        </p>
      </mat-expansion-panel>


      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Can I view my shop as a customer would see it?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Yes you can. Once you have set up your products and pickup times there will be an option to preview your shop from the viewpoint of a customer.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How long has Sprink been in business?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Sprink was incorporated on the 18th June 2020
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How big is the exclusivity area?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          That depends on how densely populated the area around your dry cleaner is. The exclusivity ranges from 650ft to 1500ft.
        </p>
        <p>
          We will set your exclusivity zone when we approve your dry cleaner.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How does the exclusivity area work?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Any Sprink customers that are within your exclusivity zone are guaranteed to be connected to your dry cleaner when they order from Sprink, even if they are closer to another dry cleaner.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How does Sprink advertise to potential customers?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          We advertise using a variety of digital platforms including: Google search, Facebook, Instagram & Messenger.
        </p>
        <p>
          Our advertising is targeted around your dry cleaners location.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How soon after I list my dry cleaner on Sprink will Sprink start advertising to people in my area?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          We update our advertising roughly once per week. This means that it could take one to two weeks before the adverts for your area start.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Can I do multiple pickup and delivery runs per day?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          We currently only allow one pickup and delivery run (Sprink run) per day where you pick up and delivery all of orders for that day.
        </p>
        <p>
          We are currently evaluating the possibility of offering multiple Sprink runs per day.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            If I list my dry cleaner on Sprink, will I still be allowed to have other customers?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Yes!
        </p>
        <p>
          Our goal is to support your business, there is no exclusivity agreement with us.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Do I need to be a dedicated dry cleaner to sign up for Sprink?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          No.
        </p>
        <p>
          You need to offer dry cleaning and have enough capacity to handle the extra customers you will receive from Sprink.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            If I list my dry cleaner on Sprink, do I need to display any Sprink branding in my shop?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          No.
        </p>
        <p>
          In the future we might provide you with free branding materials (Stickers, leaflets, etc.) however displaying them would be optional.
        </p>
      </mat-expansion-panel>

    </mat-accordion>

    <br><br>

    <div>
      <h2 class="bottomless">Didn't answer your question?</h2>
      <p>You can contact us:</p>
      <ul>
        <li>Starting a <b><span class="link" (click)="chat.maximize()">live chat</span></b> with us</li>
        <li>Call us on <b><a href="tel:02081445544" class="link">020 8144 5544</a></b></li>
        <li>Send us an email at <b><a href="mailto:wecare@sprinkclean.com" class="link">wecare@sprinkclean.com</a></b></li>
      </ul>
    </div>

  </div>
</div>


<div class="section accent" id="sign-up">
  <div class="page wide-page sprink-text">

    <div class="page-split center-vertically">
      <div class="page-split-2-3">
        <h1 class="headless">Convinced?</h1>
        <p>Start the process of becoming a parter by entering your email below. We'll send you some information about what's
        needed to list your dry cleaner on Sprink.</p>
        <mat-card>
          <app-partner-form></app-partner-form>
        </mat-card>
      </div>
      <div class="page-split-1-3">
        <img src="/assets/order-cropped.png" class="sprinky-image" alt="">
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>