<div class="section accent primary bubbles-container">
  <app-bubbles></app-bubbles>
  <div class="page center page-intro">
    <h1>Check your email!</h1>
  </div>
</div>

<div class="section">
  <div class="page center sprink-text">
    <img src="/assets/never-wait.png" class="section-icon" alt="">
    <h2>Success, you have taken the first step!</h2>
    <p>We have sent you an email, please click on the link to verify your email address.</p>
    <p>Once verified you can start setting up your Sprink shop.</p>
    <a routerLink="/login" mat-flat-button color="accent">CONTINUE</a>
  </div>
</div>

<div class="section smoke">
  <div class="page center sprink-text">
    <h2 class="section-title headless">Find out how it’s done</h2>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/w3ueq7Se-f8" class="full-width" frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</div>