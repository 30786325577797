import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../../services/analytics.service';
import { randomString } from '../../libs/random-string';
import { StateService } from '../../services/state.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-partner-form',
  templateUrl: './partner-form.component.html',
  styleUrls: ['./partner-form.component.scss']
})
export class PartnerFormComponent {

  input = ''
  loading = false
  // shopName: string
  // phoneNumber: string
  email: string
  // address: string
  success = false

  constructor(
    private http: HttpClient,
    private analytics: AnalyticsService,
    private snackBar: MatSnackBar,
    private state: StateService,
    private router: Router,
    private userService: UserService,
    private storage: StorageService
  ) { }

  async submitForm(form: NgForm) {
    if (!form.valid || this.loading) return
    this.loading = true
    try {

      let password = randomString(8)
      
      if (this.state.user) await this.userService.logout()

      let resp = <any>await this.http.post(environment.apiEndpoint + '/signup', { email: this.email, password: password, dontSendEmail: true, passwordIsAutoGenerated: true, allowAdvertising: true }).toPromise()
      if (resp && resp.status) {

        // doing login call
        let loginResp = <any>await this.http.post(environment.apiEndpoint + '/login/email', { email: this.email, password: password }).toPromise()
        if (loginResp && loginResp.status) {
          this.state.token = loginResp.data.token
          this.state.loggedIn = true
          this.storage.setLocal('token', this.state.token)
          this.analytics.signup('shop-user')
          await this.userService.getUser()
          await this.userService.initSession()
          
          let shopResp = <any>await this.http.post(environment.apiEndpoint + '/shop', { email: this.email, password }, { headers: { Authorization: this.state.token }}).toPromise()
          if (shopResp && shopResp.status) {
            this.success = true
            this.analytics.shopSignup()
            this.router.navigate(['become-a-partner-success'])
          } else {
            this.snackBar.open(shopResp.error, 'close', { duration: 2000 });
          }
        }
      } else {
        if (resp.error === 'ALREADY_EXISTS') resp.error = 'This email address already belongs to a Sprink account.'
        this.snackBar.open(resp.error, 'close', { duration: 5000 });
      }
    } catch(e) {
      console.warn(e)
    } 
    this.loading = false
    
  }

}
