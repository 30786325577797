import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-provider-terms',
  templateUrl: './service-provider-terms.component.html',
  styleUrls: ['./service-provider-terms.component.scss']
})
export class ServiceProviderTermsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
