<div class="section accent primary bubbles-container">
  <app-bubbles></app-bubbles>
  <div class="page center page-intro">
    <h1>Pricing & payouts</h1>
  </div>
</div>

<div class="section">
  <div class="page wide-page sprink-text">
    <a routerLink="/become-a-partner" mat-button class="back-button">
      <mat-icon>arrow_back_ios</mat-icon> BACK
    </a>
    <h1 class="headless">Overview</h1>
    <p>When a customer places an order with us we charge them via credit card on our app. we'll pay you out at the end of each month.</p>
    <p>When setting up your shop you will enter your bank details.</p>
    <!-- <p>Note: The price that you enter into the app is the price that the customer pays, fees are deducted from this amount.</p> -->
    <button (click)="scrollToSignup()" mat-flat-button color="primary">GET STARTED</button>
  </div>
</div>

<div class="section smoke">
  <div class="page wide-page sprink-text">
    <h1 class="headless">Payout calculator</h1>
    <p>From the total that the customer pays, we need to deduct: credit card fees, VAT and our 9.5% cut.</p>
    <p>You can use this calculator to get an idea of how this works.</p>
    <p>You will be able to see the details of your payouts at any time like this from the app.</p>
    <br><br>
    <div class="formrow break-on-mobile">
      <mat-form-field class="bottomless">
        <mat-label>Total sales</mat-label>
        <span matPrefix>£</span>
        <input matInput type="number" name="amount" min="0"  [(ngModel)]="amount" id="amount" required>
      </mat-form-field>
      <button (click)="showPayoutDetails(amount)" class="next-to-input" mat-stroked-button color="">CALCULATE</button>
    </div>
  </div>
</div>


<div class="section accent" id="sign-up">
  <div class="page wide-page sprink-text">

    <div class="page-split center-vertically">
      <div class="page-split-2-3">
        <h1 class="headless">Convinced?</h1>
        <p>Start the process of becoming a parter by entering your email below. We'll send you some information about what's
        needed to list your dry cleaner on Sprink.</p>
        <mat-card>
          <app-partner-form></app-partner-form>
        </mat-card>
      </div>
      <div class="page-split-1-3">
        <img src="/assets/order-cropped.png" class="sprinky-image" alt="">
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>