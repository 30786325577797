<div class="section smoke center-content" #signup id="signup"> <!--full-height-->
  <div class="page page-intro super-wide-page center">
    <div class="page-split">
      <div class="page-split-2-3 divide-split split-1-3 split-accent split-desktop-only">
        <!-- <div class="sticker desktop">
          <img src="/assets/5-for-30-sticker.png" alt="">
        </div> -->
        <div *ngIf="state.promotion">
          <div class="text-sticker">{{state.promotion.title}}</div>
          <br>
        </div>
        <h1>Make dry cleaning easier<br>than ordering food</h1>
        <p class="bottomless">Have your laundry and dry cleaning come to your door, <br class="hide-on-mobile">even when you're not home.</p>
        <app-postcode-search></app-postcode-search>
      </div>
      <div class="page-split-1-3 center-vertically">
        <app-sprinky-on-bike></app-sprinky-on-bike>
        <!-- <div class="sticker mobile">
          <img src="/assets/5-for-30-sticker.png" alt="">
        </div> -->
      </div>
    </div>
  </div>
  <!-- <app-scroll-indicator class="hide-on-mobile hide-on-tablet" (click)="scrollToElement(target)"></app-scroll-indicator> -->
</div>

<div class="section" id="the-content" #target>
  <div class="page super-wide-page center sprink-text">

    <h2 class="headless sprink-new-way-title">Sprink is the new way to do your dry cleaning</h2>
    <p>You choose what you want cleaned, we handle the rest.</p>
    <br>
    <!-- <h2 class="section-title headless">Easy to use</h2> -->
    <div class="columns points headless bottomless inline-points">
      <div class="col-4 mcol-12 point">
        <div class="point-icon-container"><img src="/assets/pack-small.png" class="point-icon" alt=""></div>
        <div>
          <h2>Pickup</h2>
          <p>We’ll pick up your garments at your door whether or not you're home.</p>
          <br>
          <button (click)="scrollToElement(signup)" class="signup-button headless" mat-stroked-button>GET STARTED</button>
        </div>
      </div>
      <div class="col-4 mcol-12 point">
        <div class="point-icon-container"><img src="/assets/fresh-small.png" class="point-icon" alt=""></div>
        <div>
          <h2>Clean</h2>
          <p>Our professional dry cleaners will take great care with your garments.</p>
          <br>
          <button (click)="scrollToElement(signup)" class="signup-button headless" mat-stroked-button>GET STARTED</button>
        </div>
      </div>
      <div class="col-4 mcol-12 point">
        <div class="point-icon-container"><img src="/assets/van-small.png" class="point-icon" alt=""></div>
        <div>
          <h2>Deliver</h2>
          <p>Our optional contactless delivery lets us deliver to you at any time.</p>
          <br>
          <button (click)="scrollToElement(signup)" class="signup-button headless" mat-stroked-button>GET STARTED</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="section primary" *ngIf="showShopBanner">
  <div class="page center sprink-text wide-page">
    <div class="section-icon star-icons">
      <img src="/assets/star.svg" class="star-icon" alt="">
      <img src="/assets/star.svg" class="star-icon" alt="">
      <img src="/assets/star.svg" class="star-icon" alt="">
      <img src="/assets/star.svg" class="star-icon" alt="">
      <img src="/assets/star.svg" class="star-icon" alt="">
    </div>
    <h2 class="section-title">Put your dry cleaning business on our app</h2>
    <!-- <p>Incorporate Sprink into your dry cleaning business in our BETA testing phase and gain all the benefits of a full partner at half the joining fee.</p> -->
    <a routerLink="/become-a-partner" class="signup-button headless" color="white" mat-stroked-button>FIND OUT MORE</a>
  </div>
</div>

<div class="section smoke">
  <div class="page center sprink-text wide-page">
    <div class="page-split split-other-way-on-mobile">
      <div class="split-left page-split-1-2 center-vertically center-horizontally">
        <div class="video-container">
          <video loop="" muted="muted" playsinline="" id="video">
            <source src="https://flappy.nathanfelix.com/sprink-order-video.mp4" type="video/mp4">
          </video>
        </div>
      </div>
      <div class="split-right page-split-1-2 center-vertically center-horizontally">
        <div>
          <img src="/assets/never-wait.png" class="section-icon never-wait hide-on-mobile hide-on-tablet" alt="">
          <h2 class="section-title">We never keep you waiting</h2>
          <p>Sprink is built around your busy life. You don't need to be home for pickup or delivery, meaning you won’t have
            to plan your day around your laundry anymore.</p>
          <button (click)="scrollToElement(signup)" class="signup-button" mat-flat-button color="primary">GET STARTED</button>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="section">
  <div class="page super-wide-page center sprink-text">
    <div class="dry-cleaner-items">
      <div class="dry-cleaner-item">
        <h2 class="hide-on-mobile">Dry Cleaning</h2>
        <div class="point-icon-container dont-clamp"><img src="/assets/hanger-small.png" class="point-icon" alt=""></div>
        <h2  class="hide-on-mobile">From £2.00</h2>
      </div>
      <div class="dry-cleaner-item">
        <h2 class="hide-on-mobile">Wash & Dry</h2>
        <div class="point-icon-container dont-clamp"><img src="/assets/washing-machine-small.png" class="point-icon" alt=""></div>
        <h2  class="hide-on-mobile">From £1.50</h2>
      </div>
      <div class="dry-cleaner-item">
        <h2 class="hide-on-mobile">Ironing</h2>
        <div class="point-icon-container dont-clamp"><img src="/assets/iron-small.png" class="point-icon" alt=""></div>
        <h2  class="hide-on-mobile">From £1.80</h2>
      </div>
    </div>

    <h2 class="hide-on-desktop hide-on-tablet">Dry Cleaning - Wash & Dry - Ironing</h2>

    <p class="asfdhklj">We offer a wide range of services - from dry cleaning to laundry - all in one convenient app.<br>
      We work with independent dry cleaners to provide you with the best service possible. Price and availability varies based on your area.</p>

    <button (click)="scrollToElement(signup)" class="signup-button headless" mat-flat-button color="primary">CHECK YOUR AREA</button>
  </div>
</div>

<div class="section smoke contains-split">
  <div class="page sprink-text super-wide-page">
    <div class="page-split">
      <div class="divide-split split-accent page-split-2-3 no-costs-content split-desktop-only">
        <h2 class="section-title headless">No hidden charges</h2>
        <p>We work with local businesses to provide you with a convenient laundry and dry cleaning service.</p>
        <p>Our dry cleaners have the option to charge an additional delivery fee, which will be visible in your basket when ordering.</p>
        <p>We earn a commission from the dry cleaners, meaning that your prices stay the same.</p>
        <button (click)="scrollToElement(signup)" class="signup-button" mat-flat-button color="primary">GET STARTED</button>
      </div>
      <div class="page-split-1-3 center-vertically center-horizontally hide-on-mobile hide-on-tablet">
        <img src="/assets/enjoy-square.png" class="no-hidden-costs-image" alt="">
      </div>
    </div>
  </div>
</div>

<div class="section primary">
  <div class="page center sprink-text">
    <h2 class="section-title bottomless headless">Order anywhere</h2>
    <p>You can order and manage your account from your phone, laptop, or tablet.</p>
    <img src="/assets/devices-spread.png" class="standard center" alt="">
    <button (click)="scrollToElement(signup)" class="signup-button" mat-flat-button>GET STARTED</button>
  </div>
</div>

<app-become-a-partner-banner *ngIf="showShopBanner"></app-become-a-partner-banner>
<app-footer></app-footer>