import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-become-a-partner-banner',
  templateUrl: './become-a-partner-banner.component.html',
  styleUrls: ['./become-a-partner-banner.component.scss']
})
export class BecomeAPartnerBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
