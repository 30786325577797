import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-become-a-partner-success',
  templateUrl: './become-a-partner-success.component.html',
  styleUrls: ['./become-a-partner-success.component.scss']
})
export class BecomeAPartnerSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
