<div class="page super-wide-page">

  <h2>Statuses</h2>
  <mat-checkbox class="no-flex" (change)="init()" [(ngModel)]="statuses.initial">Initial (waiting for payment)</mat-checkbox><br>
  <mat-checkbox class="no-flex" (change)="init()" [(ngModel)]="statuses.ready">Ready (waiting for pickup)</mat-checkbox><br>
  <mat-checkbox class="no-flex" (change)="init()" [(ngModel)]="statuses.pickedup">Pickedup (in processing)</mat-checkbox><br>
  <mat-checkbox class="no-flex" (change)="init()" [(ngModel)]="statuses.delivered">Delivered (done)</mat-checkbox><br>
  <mat-checkbox class="no-flex" (change)="init()" [(ngModel)]="statuses.cancelled">Cancelled</mat-checkbox><br>
  <mat-checkbox class="no-flex" (change)="init()" [(ngModel)]="statuses.error">Error</mat-checkbox><br>

  <br>
  <mat-divider></mat-divider>
  <br>
  <br>
  <div class="stats">
    <div class="list">
      <div class="list-item" *ngFor="let total of totals"><b>Total: {{total.total / 100 | currency : total.currency}}</b></div>
    </div>
  </div>
  <br>
  <mat-divider></mat-divider>

  <mat-form-field class="filter">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)">
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td mat-cell *matCellDef="let element"> {{element.status | orderStatus : element}} </td>
    </ng-container>

    <ng-container matColumnDef="createDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
      <td mat-cell *matCellDef="let element"> {{element.createDate | date : 'dd.MM.yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="totalAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
      <td mat-cell *matCellDef="let element"> {{(element.totalAmount / 100) | currency : element.currency}} </td>
    </ng-container>

    <ng-container matColumnDef="userId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> User ID </th>
      <td mat-cell *matCellDef="let element"> <a routerLink="/super-admin-user/{{element.userId}}" class="link">{{element.userId}}</a> </td>
    </ng-container>

    <ng-container matColumnDef="star" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="/order-details/{{element._id}}" mat-icon-button>
          <mat-icon>arrow_right_alt</mat-icon>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>