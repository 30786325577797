<div class="page super-wide-page">
  <mat-form-field class="filter">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)">
  </mat-form-field>

  <mat-radio-group (change)="init()" [(ngModel)]="customerType">
    <mat-radio-button value="user">Customer</mat-radio-button>
    <mat-radio-button value="admin">Shop owner</mat-radio-button>
    <mat-radio-button value="staff">Shop staff</mat-radio-button>
    <mat-radio-button value="superadmin">Super admin</mat-radio-button>
  </mat-radio-group>

  <br>
  <br>
  
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
  
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="userType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> User type </th>
      <td mat-cell *matCellDef="let element"> {{element.userType}} </td>
    </ng-container>
  
    <ng-container matColumnDef="createDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
      <td mat-cell *matCellDef="let element"> {{element.createDate | date : 'dd.MM.yyyy'}} </td>
    </ng-container>
  
    <ng-container matColumnDef="star" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="/super-admin-user/{{element._id}}" mat-icon-button>
          <mat-icon>arrow_right_alt</mat-icon>
        </a>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  
  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>