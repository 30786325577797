<div class="page super-wide-page">
  <mat-list role="navigation">
    <a routerLink="/super-admin-users" mat-list-item role="navigation" mat-ripple>
      <mat-icon matListIcon color="primary">person</mat-icon>
      <span>&nbsp;Users</span>
    </a>
    <a routerLink="/super-admin-shops" mat-list-item role="navigation" mat-ripple>
      <mat-icon matListIcon color="primary">store</mat-icon>
      <span>&nbsp;Shops</span>
    </a>
    <a routerLink="/super-admin-promotions" mat-list-item role="navigation" mat-ripple>
      <mat-icon matListIcon color="primary">local_offer</mat-icon>
      <span>&nbsp;Promotions</span>
    </a>
    <a routerLink="/super-admin-orders" mat-list-item role="navigation" mat-ripple>
      <mat-icon matListIcon color="primary">shopping_cart</mat-icon>
      <span>&nbsp;Orders</span>
    </a>
    <a routerLink="/super-admin-shop-payouts" mat-list-item role="navigation" mat-ripple>
      <mat-icon matListIcon color="primary">toll</mat-icon>
      <span>&nbsp;Payouts</span>
    </a>
    <a href="https://datastudio.google.com/u/0/reporting/399b0ea5-c7dc-4ab6-a9e8-6b8301b69cf5" target="_blank" mat-list-item role="navigation" mat-ripple>
      <mat-icon matListIcon color="primary">analytics</mat-icon>
      <span>&nbsp;Reporting [Google data studio]</span>
    </a>
    <a href="https://app.eu.mailgun.com/app/sending/domains/mailer.sprinkclean.com/analytics" target="_blank" mat-list-item role="navigation" mat-ripple>
      <mat-icon matListIcon color="primary">analytics</mat-icon>
      <span>&nbsp;Transactional email reporting [Mailgun]</span>
    </a>
    <a href="https://app.eu.mailgun.com/app/sending/domains/sprinkclean.co.uk/analytics" target="_blank" mat-list-item role="navigation" mat-ripple>
      <mat-icon matListIcon color="primary">analytics</mat-icon>
      <span>&nbsp;Marketing email reporting [Mailgun]</span>
    </a>
    <a routerLink="/super-admin-campaigns" mat-list-item role="navigation" mat-ripple>
      <mat-icon matListIcon color="primary">campaign</mat-icon>
      <span>&nbsp;Campaigns</span>
    </a>
  </mat-list>

  <!-- <button mat-flat-button (click)="importExport()" *ngIf="isProduction">Export data to test</button> -->
</div>