import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sprinky-on-bike',
  templateUrl: './sprinky-on-bike.component.html',
  styleUrls: ['./sprinky-on-bike.component.scss']
})
export class SprinkyOnBikeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
