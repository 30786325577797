<div class="section full-height primary center-content bubbles-container">
  <app-bubbles></app-bubbles>
  <div class="page has-centered-content">
    <div class="page-centered-content center small">
      <h1>Password reset</h1>
      <mat-card>
        <mat-card-content>
          <form name="resetForm" #resetForm="ngForm" (submit)="submitForm(resetForm)">
            <mat-form-field>
              <input matInput type="email" name="email" [(ngModel)]="email" id="email" placeholder="Email" disabled><br>
            </mat-form-field>
            <mat-form-field>
              <input matInput type="password" name="password" [(ngModel)]="password" id="password" placeholder="Password" required><br>
            </mat-form-field>
            <button *ngIf="!loading" mat-flat-button color="primary" class="change-button">CHANGE PASSWORD</button>
            <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
          </form>
        </mat-card-content>
      </mat-card>
  </div>
</div>