<h2 class="headless">Editing product</h2>

<div *ngIf="!product.name">
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Search</mat-label>
    <input matInput type="search" name="product-name-search" [(ngModel)]="search" (input)="filter()">
  </mat-form-field>

  <div class="formrow">
    <mat-form-field appearance="fill">
      <mat-label>Category</mat-label>
      <mat-select name="group" [(ngModel)]="selectedGroup" (selectionChange)="changeGroup()">
        <mat-option>-- None --</mat-option>
        <mat-option *ngFor="let group of groups" [value]="group">
          {{group.groupName}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="!selectedGroup">
      <mat-label>Sub category</mat-label>
      <mat-select name="group" [disabled]="true">
        <mat-option>-- None --</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="selectedGroup">
      <mat-label>Sub category</mat-label>
      <mat-select name="sub-group" [(ngModel)]="selectedSubGroup" (selectionChange)="filter()">
        <mat-option>-- None --</mat-option>
        <mat-option *ngFor="let subGroup of selectedGroup.subGroups" [value]="subGroup">
          {{subGroup.subGroupName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="list-container">
    <div class="list">
      <div class="list-item product" *ngFor="let prod of filteredProducts" (click)="!prod.active && selectProduct(prod)" [ngClass]="{'already-active': prod.active, 'selected': prod.isSelected}">
        <div class="product-icon"><img src="/assets/product-icons/{{prod.icon}}.svg"></div>
        <div class="product-meta">
          <b>{{prod.name}} <span *ngIf="prod.unitType">(per {{prod.unitType}})</span></b>
          <h4>{{prod.group}} ><span *ngIf="prod.subGroup"> {{prod.subGroup}} ></span> {{prod.name}}</h4>
        </div>
        <mat-icon *ngIf="prod.active || prod.isSelected" color="primary">done</mat-icon>
      </div>
      <div *ngIf="filteredProducts && !filteredProducts.length" class="center no-results">
        <b>No results found.</b>
      </div>
    </div>
  </div>
  <div class="center">
    <br>
    Want to suggest a new product?<br>
    <span class="link" (click)="chat.maximize()">Chat with us</span>
  </div>
</div>

<div *ngIf="product.name">
  <form name="editForm" #editForm="ngForm" (submit)="save(editForm)">
    
    <div class="product-title">
      <div class="meta">
        <h3>{{product.name}} <span *ngIf="product.unitType">(per {{product.unitType}})</span></h3>
        <span *ngIf="product.description">{{product.description}}</span>
      </div>
      <button mat-icon-button (click)="product.name = undefined" color="primary" type="button">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  
    <div class="formrow">
      <mat-form-field appearance="fill">
        <mat-label>Price</mat-label>
        <span matPrefix>{{shop.currency}}&nbsp;</span>
        <input matInput type="number" appNumberLimit [maxVal]="999" [minVal]="0" name="productPrice" [(ngModel)]="product.price" required>
        <span matSuffix><span *ngIf="product.unitType">/ {{product.unitType}}</span><span *ngIf="!product.unitType">ea.</span></span>
      </mat-form-field>
    </div>
  
    <div *ngIf="product.price === 0" class="zero-price-warning">
      <b>Note:</b> If you put the price of your product to £0 it means it is <b>free</b>.
      If the price should depend on the size, complexity, etc. then you should create multiple products
    </div>
    
    <div class="buttons">
      <button mat-stroked-button color="warn" type="button" (click)="deleteProduct()">DELETE</button>
      <div class="spacer"></div>
      <button mat-flat-button color="accent">APPLY</button>
    </div>

    <div class="center">
      <br>
      <span>Price shown to customers: <b>{{product.price * 1.3 | currency : 'GBP'}} </b></span>
      <b><span *ngIf="product.unitType">/ {{product.unitType}}</span><span *ngIf="!product.unitType">ea.</span></b>
      <span> (after 30% markup)</span>
      <p class="primary-text bottomless"><b>This lets us offer customers a discounted price.</b></p>
    </div>
  </form>
</div>