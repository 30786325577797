<div class="section">
  <div class="page super-wide-page">
    <app-shops-map [shops]="dataSource.filteredData"></app-shops-map>
  </div>
</div>
<div class="page super-wide-page">

  <div class="center" *ngIf="numOfShopsWaitingForReview > 0">
    <h2 class="bottomless">{{numOfShopsWaitingForReview}} shops waiting for review</h2>
    <mat-checkbox class="no-flex" (change)="init()" [(ngModel)]="viewShopsForReview">View shops</mat-checkbox>
  </div>
  
  <div class="center">
    <h3 class="bottomless">Open shops only</h3>
    <mat-checkbox class="no-flex" (change)="init()" [(ngModel)]="viewOpenShopsOnly">View open shops only</mat-checkbox>
  </div>

  <div class="center">
    <h3 class="bottomless">Named shops only</h3>
    <mat-checkbox class="no-flex" (change)="init()" [(ngModel)]="namedShopsOnly">View named shops only</mat-checkbox>
  </div>

  <div class="center">
    <h3 class="bottomless">Coverage</h3>
    <span *ngIf="coveragePercentage"><h2 class="headless">{{coveragePercentage.toFixed(2)}}%</h2></span>
    <div *ngIf="!coveragePercentage">
      <button mat-stroked-button (click)="calcCoverage()">CALC COVERAGE</button>
    </div>
  </div>

  <mat-form-field class="filter">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)">
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
      <td mat-cell *matCellDef="let element"> {{element.created_at | date : 'dd.MM.yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="isVerified">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Shop is verified </th>
      <td mat-cell *matCellDef="let element"> {{element.isVerified ? 'Yes' : element.submittedDocuments ? 'Waiting' : 'No'}} </td>
    </ng-container>

    <ng-container matColumnDef="isOpen">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Shop is open </th>
      <td mat-cell *matCellDef="let element"> {{element.isOpen ? 'Yes' : 'No'}} </td>
    </ng-container>

    <ng-container matColumnDef="productCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Product count </th>
      <td mat-cell *matCellDef="let element"> {{element.products.length}} </td>
    </ng-container>

    <ng-container matColumnDef="star" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="/super-admin-shop/{{element._id}}" mat-icon-button>
          <mat-icon>arrow_right_alt</mat-icon>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  
  <br><br>
  <a routerLink="/super-admin-shops/hidden" class="link">Hidden shops</a><br>
  <a routerLink="/super-admin-shops" class="link">Not hidden shops</a>
</div>