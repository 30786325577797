<div class="section full-height">
  <div class="page">
    <h1>SPRINK SERVICES LTD Privacy Policy</h1>

    <p>You can download our privacy policy here:</p>
    
    <a href="/assets/documents/sprink-privacy-policy.pdf" target="_blank" class="document-link">
      <img src="/assets/doc.svg" alt="">
      <span>Privacy policy</span>
      <div class="download-icon">
        <img src="/assets/download-white.svg" alt="">
      </div>
    </a>
    
    <a href="/assets/documents/sprink-cookies-policy.pdf" target="_blank" class="document-link">
      <img src="/assets/doc.svg" alt="">
      <span>Cookies policy</span>
      <div class="download-icon">
        <img src="/assets/download-white.svg" alt="">
      </div>
    </a>
    
  </div>
</div>

<app-footer></app-footer>