<div class="section full-height primary center-content bubbles-container">
  <app-bubbles></app-bubbles>
  <div class="page has-centered-content">
    <div class="page-centered-content center small">
      <h1>Password Reset</h1>
      <mat-card>
        <mat-card-content *ngIf="!sucess">
          <form name="sendPasswordResetForm" #sendPasswordResetForm="ngForm" (submit)="submitForm(sendPasswordResetForm)">
            <mat-form-field>
              <input matInput type="email" name="email" [(ngModel)]="email" id="email" placeholder="Email" required><br>
            </mat-form-field>
            <button *ngIf="!loading" mat-flat-button color="primary" class="send-button">SEND EMAIL</button>
            <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
          </form>
        </mat-card-content>
        <mat-card-content *ngIf="sucess">
          <p>A password reset link has been sent to <b>{{email}}</b>.</p>
        </mat-card-content>
      </mat-card>
      <a routerLink="/login" class="link back-to-login-button">Back to login</a>
    </div>
  </div>
</div>