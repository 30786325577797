<div class="section full-height">
  <div class="page">
    <h1>SPRINK SERVICES LTD terms of service for service providers:</h1>

    <a href="/assets/documents/sprink-merchant-agreement.pdf" target="_blank" class="document-link">
      <img src="/assets/doc.svg" alt="">
      <span>Merchant agreement</span>
      <div class="download-icon">
        <img src="/assets/download-white.svg" alt="">
      </div>
    </a>
    
    <a href="/assets/documents/sprink-dry-cleaning-agent-or-drivers-agreement.pdf" target="_blank" class="document-link">
      <img src="/assets/doc.svg" alt="">
      <span>Drivers agreement</span>
      <div class="download-icon">
        <img src="/assets/download-white.svg" alt="">
      </div>
    </a>

    <a href="/assets/documents/sprink-terms-and-conditions.pdf" target="_blank" class="document-link">
      <img src="/assets/doc.svg" alt="">
      <span>Terms and conditions</span>
      <div class="download-icon">
        <img src="/assets/download-white.svg" alt="">
      </div>
    </a>

    <a href="/assets/documents/sprink-gdpr-policy.pdf" target="_blank" class="document-link">
      <img src="/assets/doc.svg" alt="">
      <span>GDPR policy</span>
      <div class="download-icon">
        <img src="/assets/download-white.svg" alt="">
      </div>
    </a>

    <a href="/assets/documents/sprink-privacy-policy.pdf" target="_blank" class="document-link">
      <img src="/assets/doc.svg" alt="">
      <span>Privacy policy</span>
      <div class="download-icon">
        <img src="/assets/download-white.svg" alt="">
      </div>
    </a>

    <a href="/assets/documents/sprink-cookies-policy.pdf" target="_blank" class="document-link">
      <img src="/assets/doc.svg" alt="">
      <span>Cookies policy</span>
      <div class="download-icon">
        <img src="/assets/download-white.svg" alt="">
      </div>
    </a>

    <br>
    <h2>Extra information</h2>
    <ul>
      <li>All prices are in GBP</li>
      <li>All prices include VAT</li>
    </ul>
    
  </div>
</div>

<app-footer></app-footer>