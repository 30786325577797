<div class="section accent primary bubbles-container">
  <app-bubbles></app-bubbles>
  <div class="page center page-intro">
    <h1>Our story</h1>
  </div>
</div>

<div class="section smoke">
  <div class="page center wide-page sprink-text">
    <h1 class="headless">How we started</h1>

    <p>The initial drive to create Sprink was as our own way to support local dry cleaners in these difficult times of the
    Covid-19 lockdown.</p>
    
    <p>Sprink was founded with the goal of transforming the declining dry cleaning industry into a growing one by incorporating
    modern technology and trend-setting innovations.</p>
    
    <!-- <p>We decided that contactless delivery should be our default option as a safe and convenient reimagination of home
    delivery, you never have to interact with the delivery driver directly.</p> -->
  </div>
</div>

<div class="section">
  <div class="page center sprink-text wide-page">
    <h1 class="headless">Who we are</h1>

    <p>Between us, we have over ten years of experience in managing commercial online platforms that generate over £200 million per year.</p>
    
    <p>Our venture into the dry cleaning world is being mentored by Dino Antonio Parrella, who has worked on dozens of digital
    start-ups over several decades.</p>
    <br>
    <div class="founders">
      <div class="founder">
        <div class="founder-image">
          <img src="/assets/founder-nathan.jpg" alt="">
        </div>
        <div class="founder-meta">
          <h3>Nathan Felix</h3>
          <p>Technical design & development</p>
        </div>
      </div>
      <div class="founder">
        <div class="founder-image">
          <img src="/assets/founder-silas.jpg" alt="">
        </div>
        <div class="founder-meta">
          <h3>Silas Felix</h3>
          <p>Creative Director</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="section smoke">
  <div class="page center sprink-text">
    <h1 class="headless">Our goals</h1>
    <h3 class="headless bottomless">Support small businesses</h3>
    <p>As a start-up, we know how hard it can be to compete with big businesses and franchises. That’s why we choose to support
    local dry cleaners while allowing them to stay independent.</p>
    <br>
    <h3 class="bottomless">Revolutionize the dry cleaning industry</h3>
    <p>Dry cleaning is being left behind in the new digital world of the 21st century. We want to change this!</p>
  </div>
</div>

<div class="section accent" id="sign-up">
  <div class="page wide-page sprink-text">
    <div class="page-split center-vertically">
      <div class="page-split-2-3">
        <h1 class="headless">Want to join us?</h1>
        <p>Start the process of becoming a parter by entering your email below. We'll send you some information about what's
        needed to list your dry cleaner on Sprink.</p>
        <mat-card>
          <app-partner-form></app-partner-form>
        </mat-card>
      </div>
      <div class="page-split-1-3">
        <img src="/assets/order-cropped.png" class="sprinky-image" alt="">
      </div>
    </div>
  </div>
</div>

<app-become-a-partner-banner></app-become-a-partner-banner>
<app-footer></app-footer>