import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChatService } from '../../services/chat.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-shop-setup',
  templateUrl: './shop-setup.component.html',
  styleUrls: ['./shop-setup.component.scss']
})
export class ShopSetupComponent {

  shop: any
  user: any
  payoutSettings: any
  passwordChangeChecked = false
  personalDetailsChecked = false
  companySettingsChecked = false
  payoutSettingsChecked = false
  productSettingsChecked = false
  timesSettingsChecked = false
  allowSubmitVerificationDocuments = false

  constructor(private route: ActivatedRoute, public chat: ChatService, private state: StateService, private userService: UserService) {
    this.shop = this.route.snapshot.data.shop
    this.payoutSettings = this.route.snapshot.data.payoutSettings
    this.user = this.state.user
    this.userService.requestNotificationsPermissions()

    this.passwordChangeChecked = !this.user.passwordIsAutoGenerated
    this.personalDetailsChecked = this.user.firstName && this.user.lastName
    this.companySettingsChecked = this.shop.name && this.shop.timeZone && this.shop.phoneNumber && this.shop.currency && this.shop.address.formatted
    this.payoutSettingsChecked = !!this.payoutSettings
    this.productSettingsChecked = !!this.shop.products.length
    this.timesSettingsChecked = this.shop.pickupTimes.monday || this.shop.pickupTimes.tuesday || this.shop.pickupTimes.wednesday || this.shop.pickupTimes.thursday || this.shop.pickupTimes.friday || this.shop.pickupTimes.saturday || this.shop.pickupTimes.sunday

    this.allowSubmitVerificationDocuments = this.passwordChangeChecked && this.personalDetailsChecked && this.companySettingsChecked && this.payoutSettingsChecked && this.productSettingsChecked && this.timesSettingsChecked
    
  }
}
