<div class="footer">
  <div class="page wide-page center-vertically">
    <b class="copyright">© {{current | date : 'yyyy'}} Sprink.</b>
    <a href="https://facebook.com/sprinkclean" target="_blank" rel="noopener" class="social-icon" name="facebook link">
      <img src="/assets/facebook-white.svg" alt="">
    </a>
    <a href="https://instagram.com/sprinkclean" target="_blank" rel="noopener" class="social-icon" name="instagram link">
      <img src="/assets/instagram-white.svg" alt="">
    </a>
  </div>

  <div class="page wide-page">
    <div class="footer-menu-container">
      <div class="footer-menu">
        <a routerLink="/terms" class="menu-item">Terms of service</a>
        <a routerLink="/terms" class="menu-item">Service provider terms</a>
        <a routerLink="/privacy" class="menu-item">Privacy</a>
      </div>
      <div class="footer-menu">
        <a routerLink="/about" class="menu-item">About Sprink</a>
        <a routerLink="/help" class="menu-item">Help (Customer)</a>
        <a routerLink="/shop-faqs" class="menu-item">Help (Dry cleaner)</a>
      </div>
      <div class="footer-menu">
        <!-- <a routerLink="/about" class="menu-item">About Sprink</a> -->
        <!-- <a routerLink="/help" class="menu-item">Help</a> -->
      </div>
      <div class="footer-menu">
        <!-- <a routerLink="/about" class="menu-item">About Sprink</a> -->
        <!-- <a routerLink="/help" class="menu-item">Help</a> -->
      </div>
    </div>
  </div>

  <div class="page wide-page">
    Company registration number: 12680429
  </div>
</div>