<div class="section primary full-height center-content bubbles-container">
  <app-bubbles></app-bubbles>
  <div class="page">
    <h2 class="page-title">Sorry!</h2>
    <p class="page-sub-title">This page doesn't exist.</p>

    <div class="center">
      <p class="page-text">Please feel free to take out your frustration on the bubbles.</p>
      <a routerLink="/" mat-stroked-button color="white">TO HOME PAGE</a>
    </div>
  </div>
</div>