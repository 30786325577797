import { Component } from '@angular/core';

@Component({
  selector: 'app-cant-charge-extra-dialog',
  templateUrl: './cant-charge-extra-dialog.component.html',
  styleUrls: ['./cant-charge-extra-dialog.component.scss']
})
export class CantChargeExtraDialogComponent {

  constructor() { }

}
